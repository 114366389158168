<template>
  <div>
    <stockComponent :isManage="false"/>
  </div>
</template>

<script>
import stockComponent from '@/components/stock/Index'

export default {
  components: {
    stockComponent
  }
}
</script>
